export const GoogleIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2949_2167"
      type="luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="17"
    >
      <path d="M16 0.5H0V16.5H16V0.5Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2949_2167)">
      <path
        d="M15.68 8.6823C15.68 8.11502 15.6291 7.56958 15.5346 7.0459H8V10.1405H12.3054C12.12 11.1405 11.5564 11.9877 10.7091 12.555V14.5623H13.2946C14.8073 13.1696 15.68 11.1186 15.68 8.6823Z"
        fill="#4285F4"
      />
      <path
        d="M7.99995 16.4995C10.16 16.4995 11.9708 15.7831 13.2944 14.5614L10.709 12.5541C9.99267 13.0341 9.07627 13.3177 7.99995 13.3177C5.91627 13.3177 4.15267 11.9104 3.52355 10.0195H0.85083V12.0923C2.16723 14.7068 4.87267 16.4995 7.99995 16.4995Z"
        fill="#34A853"
      />
      <path
        d="M3.5236 10.0199C3.3636 9.53995 3.27272 9.02723 3.27272 8.49995C3.27272 7.97267 3.3636 7.45995 3.5236 6.97995V4.90723H0.85088C0.30912 5.98723 0 7.20907 0 8.49995C0 9.79083 0.30912 11.0127 0.85088 12.0927L3.5236 10.0199Z"
        fill="#FBBC04"
      />
      <path
        d="M7.99995 3.68184C9.17443 3.68184 10.229 4.08544 11.0581 4.87816L13.3527 2.5836C11.9672 1.29272 10.1563 0.5 7.99995 0.5C4.87267 0.5 2.16723 2.29272 0.85083 4.90728L3.52355 6.98C4.15267 5.08912 5.91627 3.68184 7.99995 3.68184Z"
        fill="#E94235"
      />
    </g>
  </svg>
);
